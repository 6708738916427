
// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'
import Button from '../../components/button'

class RegisterCarerPage extends React.Component {
	render() {
		return <Layout className="page--register page--register-carer nav-blue-half no-footer register-flow">
			<Seo title="Register as a Carer" />
			<DuoSection register>
				<div>
					<div className="content-wrap">
						<h1 className="color--endeavour">Register</h1>
						<p>Are you part of the NHS or of another organisation?</p>

						<div className="register-buttons">
							<Button to="/register/carer/nhs" colorEndeavour noDots>NHS</Button>
							<Button to="/register/carer/non-nhs" colorEndeavour noDots>Other Organisation</Button>
						</div>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default RegisterCarerPage
